body {
  margin: 0;
  font-family: 'PF BeauSans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: local('PFBeauSansPro-BoldItalic'),
        url('../public/fonts/PFBeauSansPro-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro Light';
    src: local('PFBeauSansPro-Light'),
        url('../public/fonts/PFBeauSansPro-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro Thin';
    src: local('PFBeauSansPro-ThinItalic'),
        url('../public/fonts/PFBeauSansPro-ThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro XThin';
    src: local('PFBeauSansPro-XThinItalic'),
        url('../public/fonts/PFBeauSansPro-XThinItalic.woff2') format('woff2');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro Thin';
    src: local('PFBeauSansPro-Thin'),
        url('../public/fonts/PFBeauSansPro-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'PFBeauSansPro-SemiBold';
    src: local('PFBeauSansPro-SemiBold'),
        url('../public/fonts/PFBeauSansPro-SemiBold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: local('PFBeauSansPro-Bold'),
        url('../public/fonts/PFBeauSansPro-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'PFBeauSansPro-SemiBold';
    src: local('PFBeauSansPro-SemiBoldItalic'),
        url('../public/fonts/PFBeauSansPro-SemiBoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: local('PFBeauSansPro-LightItalic'),
        url('../public/fonts/PFBeauSansPro-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: local('PFBeauSansPro-Italic'),
        url('../public/fonts/PFBeauSansPro-Italic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'PF BeauSans Pro';
    src: local('PFBeauSansPro-Regular'),
        url('../public/fonts/PFBeauSansPro-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

